@font-face {
  font-family: 'TAN-PEARL';
  src: local('TAN-PEARL'), url(./fonts/TAN-PEARL.ttf) format('truetype');
}

@font-face {
  font-family: 'NOW-MEDIUM';
  src: local('NOW-MEDIUM'), url(./fonts/Now-Medium.otf) format('truetype');
}

@font-face {
  font-family: 'NOW-REGULAR';
  src: local('NOW-REGULAR'), url(./fonts/Now-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'NOW-THIN';
  src: local('NOW-THIN'), url(./fonts/Now-Thin.otf) format('truetype');
}

@font-face {
  font-family: 'Cormorant-Regular';
  src: local('Cormorant-Regular'), url(./fonts/CormorantGaramond-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Cormorant-Light';
  src: local('Cormorant-Light'), url(./fonts/CormorantGaramond-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Cormorant-Bold';
  src: local('Cormorant-Bold'), url(./fonts/CormorantGaramond-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Capsuula';
  src: local('Capsuula'), url(./fonts/Capsuula.ttf) format('truetype');
}

